import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { InventrySelector } from "../../store/InventryScreen/Selector";
import { InventryCreateValidator } from "../../validators/Validators";
import { productSelector } from "../../store/product/product.model";
import {
  getInventryDataRequest,
  postInventryDataRequest,
  updateInventryDataRequest,
} from "../../store/InventryScreen/Action";
import { getProductRequest } from "../../store/product/product.action";
import { selectDefault } from "../../utils/Constants";

const InventoryPage = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(InventryCreateValidator),
  });
  console.log(errors, "56789sj");
  // Selectors
  const { getInventData, postSuccess, updateSuccess, deleteSuccess } =
    useSelector(InventrySelector);
  const { getProductData } = useSelector(productSelector);
console.log(getInventData,"getInventData")
  // States
  const [id, setId] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages = Math.ceil(getInventData?.count / rowsPerPage) || 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };
  const onSubmitHandler = (formData) => {
    delete formData.opening_qty
    delete formData.outward_qty
    delete formData.unit_type 
    if (id === 0) {
      dispatch(postInventryDataRequest(formData));
    } else {
      dispatch(updateInventryDataRequest({ formData, id }));
    }
  };

  const handleEdit = (val) => {
    setId(val.id);
    reset({
      product_id: val.product_id,
      opening_qty: val.opening_qty,
      current_qty: val.current_qty,
      inward_qty: "",
      outward_qty: "",
    });
  };

  //   const handleDelete = (id) => {
  //     dispatch(deleteInventryDataRequest(id));
  //   };

  useEffect(() => {
    const payload1 = {
      query: searchQuery,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getInventryDataRequest(payload1));
    const payload = {
      query: 0,
      prod_id: 0,
      page: "",
      limit: "",
    };
    dispatch(getProductRequest(payload));
  }, [rowsPerPage,currentPage,searchQuery]);

  useEffect(() => {
    if (postSuccess || updateSuccess || deleteSuccess) {
      const payload1 = {
        query: searchQuery,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getInventryDataRequest(payload1));
      setId(0);
      reset({
        product_id: "",
        opening_qty: "",
        current_qty: "",
        inward_qty: "",
        outward_qty: "",
      });
    }
  }, [postSuccess, updateSuccess, deleteSuccess, dispatch, reset]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Inventory Management</h2>
              <p>Add, edit or delete inventory items</p>
            </div>
            <div>
              <input
                className="form-control bg-white"
                type="text"
                placeholder="Search Inventory"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={3}>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="mb-4">
                      <label className="form-label">Product Name</label>
                      <select
                        {...register("product_id")}
                        className="form-control"
                      >
                        <option value={selectDefault} disabled>
                          SELECT
                        </option>
                        {getProductData?.data?.map((product) => (
                          <option key={product.id} value={product.id}>
                            {product.name}
                          </option>
                        ))}
                      </select>
                      {errors.product_id && (
                        <p className="text-danger">
                          {errors.product_id.message}
                        </p>
                      )}
                    </div>
                    {/* <div className="mb-4">
                      <label className="form-label">Opening Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("opening_qty")}
                      />
                      {errors.opening_qty && (
                        <p className="text-danger">
                          {errors.opening_qty.message}
                        </p>
                      )}
                    </div> */}
                    <div className="mb-4">
                      <label className="form-label">Current Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("current_qty")}
                      />
                      {errors.current_qty && (
                        <p className="text-danger">
                          {errors.current_qty.message}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      <label className="form-label">Inward Quantity</label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("inward_qty")}
                      />
                    </div>
                    {/* <div className="mb-4">
                      <label className="form-label">Outward Quantity</label>
                      <input
                        type="number"
                        defaultValue="0"
                        className="form-control"
                        {...register("outward_qty")}
                      />
                    </div> */}
                    <div className="d-grid">
                      <button className="btn btn-primary" type="submit">
                        {id === 0 ? "Create Inventory" : "Update Inventory"}
                      </button>
                    </div>
                  </form>
                </Col>

                <Col md={9}>
                  <div className="table-responsive">
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Product</th>
                          {/* <th>Opening Qty</th> */}
                          <th>Current Qty</th>
                          <th>Inward Qty</th>
                          {/* <th>Outward Qty</th> */}
                          <th>Created At</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getInventData &&
                          getInventData.map((item, index) => (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item?.products?.name}</td>
                              {/* <td>{item?.opening_qty}</td> */}
                              <td>{item?.current_qty}</td>
                              <td>{item?.inward_qty}</td>
                              {/* <td>{item?.outward_qty}</td> */}
                              <td>
                                {dayjs(item?.created_at).format("DD-MM-YY")}
                              </td>
                              <td>
                                <button
                                  className="btn btn-light"
                                  onClick={() => handleEdit(item)}
                                >
                                  Edit
                                </button>
                                <button
                                  className="btn btn-danger" /*  onClick={() => handleDelete(item.id)} */
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <div className="pagination-area mt-15 mb-50">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-end">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          <i className="material-icons md-chevron_left"></i>
                        </a>
                      </li>

                      {[...Array(totalPages)]?.map((_, index) => (
                        <li
                          key={index + 1}
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                          onClick={() => handlePageChange(index + 1)}
                        >
                          <a className="page-link" href="#">
                            {index + 1}
                          </a>
                        </li>
                      ))}

                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <a
                          className="page-link"
                          href="#"
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          <i className="material-icons md-chevron_right"></i>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Row>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default InventoryPage;
