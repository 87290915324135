import { dashboardActionTypes } from "./dashboard.model";
import * as dashboardAction from "./dashboard.action"
import { getcardsApi, getDashboardApi } from "./dashboard.api";
import { call, put, takeEvery } from "redux-saga/effects";
function* handleGetCardData(action) {
    try {
      const response = yield call(getcardsApi, action.payload);
      yield put(dashboardAction.getCardDataSuccess(response));
      
    } catch (e) {
      yield put(dashboardAction.getCardDataFailure(e));
    }
  }
  
  function* handleGetDashBoardData(action) {
    try {
      const response = yield call(getDashboardApi, action.payload);
      yield put(dashboardAction.getDashBoardDataSuccess(response));
      
    } catch (e) {
      yield put(dashboardAction.getDashBoardDataFailure(e));
    }
  }

export function* dashboardSaga() {
    yield takeEvery(dashboardActionTypes.GETCARDDATA_REQUEST, handleGetCardData);
    yield takeEvery(dashboardActionTypes.GETDASHBOARDDATA_REQUEST, handleGetDashBoardData);
}
