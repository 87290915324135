import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import avatar4 from "../../assets/imgs/people/avatar4.jpg";
import avatar3 from "../../assets/imgs/people/avatar3.jpg";
import avatar2 from "../../assets/imgs/people/avatar2.jpg";
import { getCardDataRequest } from "../../store/dashboard/dashboard.action";
import { dashboardSelector } from "../../store/dashboard/dashboard.model";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
function Home() {
  const dispatch = useDispatch();

  const { cardData, dashData } = useSelector(dashboardSelector);

console.log(cardData, "6789")
    /* useEffect */
    useEffect(() => {
      dispatch(getCardDataRequest());
    }, []);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Dashboard</h2>
              <p>Whole data about your business here</p>
            </div>
            {/* <div>
              <a className="btn btn-primary" href="#">
                <i className="text-muted material-icons md-post_add"></i>Create
                report
              </a>
            </div> */}
          </div>
          <Row>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Products</h6>
                    <span>{cardData?.total_products}</span>
                    <span className="text-sm">
                      in {cardData?.total_categories} categories and {cardData?.total_brands} brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Total</h6>
                    <span>{cardData?.total_orders}</span>
                    <span className="text-sm">Increase by 15% from Last year</span>
                    {/* <span className="text-sm">
                      Shipping fees are not included
                    </span> */}
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    {/* <i className="text-success material-icons md-local_shipping"></i> */}
                    <i className="text-warning material-icons md-monetization_on"></i> 
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Total</h6>
                    <span>₹{parseFloat(cardData?.total_revenue).toFixed(2)}</span>
                    {/* <span className="text-sm">Excluding orders in transit</span> */}
                    <span className="text-sm">Increase by 15% from Last year</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit %</h6>
                    <span>{cardData?.monthly_profit}</span>
                    <span className="text-sm">Increase by 15% from Last year</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">New Products added</h6>
                    <span>{cardData?.new_products}</span>
                    <span className="text-sm">
                    in {cardData?.total_categories} categories and {cardData?.total_brands} brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Received This Month</h6>
                    <span>{cardData?.monthly_orders}</span>
                    <span className="text-sm">Increase by 15% from Last month</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Received This Month</h6>
                    <span>₹{parseFloat(cardData?.monthly_revenue).toFixed(2)}</span>
                    <span className="text-sm">Increase by 15% from Last month</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % this Month</h6>
                    <span>{cardData?.monthly_profit}</span>
                    <span className="text-sm">Increase by 15% from Last month</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Products out of stock</h6>
                    <span>{cardData?.out_of_stock_products}</span>
                    <span className="text-sm">
                    in {cardData?.total_categories} categories and {cardData?.total_brands} brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Received Today</h6>
                    <span>{cardData?.today_orders}</span>
                    <span className="text-sm">Increase by 15% from yesterday</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Received Today</h6>
                    <span>₹{parseFloat(cardData?.today_revenue).toFixed(2)}</span>
                    <span className="text-sm">Increase by 15% from yesterday</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % Today</h6>
                    <span>{cardData?.today_profit}</span>
                    <span className="text-sm">Increase by 15% from yesterday</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Products out of stock Soon</h6>
                    <span>{cardData?.out_of_stock_soon}</span>
                    <span className="text-sm">
                      in 8 categories and 4 brands
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Forecast This month</h6>
                    <span>{cardData?.orders_forecast}</span>
                    <span className="text-sm">Increase by 15% from last month</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Forecast this month</h6>
                    <span>₹{parseFloat(cardData?.revenue_forecast).toFixed(2)}</span>
                    <span className="text-sm">Increase by 15% from last month</span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % Forecast this month</h6>
                    <span>{cardData?.monthly_profit}</span>
                    <span className="text-sm">Increase by 15% from last month</span>
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={4} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Top Brands this month</h5>
                  <span className="text-muted font-xs">Wipro</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "15%" }}
                    >
                      15%
                    </div>
                  </div>
                  <span className="text-muted font-xs">GM</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "65%" }}
                    >
                      65%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Finolex</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "51%" }}
                    >
                      {" "}
                      51%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Anchor</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Legrand</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                </article>
              </div>
            </Col>
            <Col xl={4} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Fast Selling Products</h5>
                  <span className="text-muted font-xs">Finolex wires</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "15%" }}
                    >
                      15%
                    </div>
                  </div>
                  <span className="text-muted font-xs">GM switches</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "65%" }}
                    >
                      65%
                    </div>
                  </div>
                  <span className="text-muted font-xs">GM Adapter</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "51%" }}
                    >
                      {" "}
                      51%
                    </div>
                  </div>
                  <span className="text-muted font-xs">GM Panels</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Anchor Panels</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                </article>
              </div>
            </Col>
            <Col xl={4} lg={12}>
              <div className="card mb-4">
                <article className="card-body">
                  <h5 className="card-title">Slow selling Products</h5>
                  <span className="text-muted font-xs">Anchor Switch</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "15%" }}
                    >
                      15%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Anchor Socket</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "65%" }}
                    >
                      65%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Legrand Panel</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "51%" }}
                    >
                      {" "}
                      51%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Legrand Wires</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                  <span className="text-muted font-xs">Finolex Switches</span>
                  <div className="progress mb-3">
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: "80%" }}
                    >
                      {" "}
                      80%
                    </div>
                  </div>
                </article>
              </div>
            </Col>
          </Row>
          <div className="card mb-4">
            <header className="card-header">
              <h4 className="card-title">Latest orders</h4>
              <div className="row align-items-center">
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  <div className="custom_select">
                    <select className="form-select select-nice">
                      <option selected="">All Categories</option>
                      <option>Women&apos;s Clothing</option>
                      <option>Men&apos;s Clothing</option>
                      <option>Cellphones</option>
                      <option>Computer &amp; Office</option>
                      <option>Consumer Electronics</option>
                      <option>Jewelry &amp; Accessories</option>
                      <option>Home &amp; Garden</option>
                      <option>Luggage &amp; Bags</option>
                      <option>Shoes</option>
                      <option>Mother &amp; Kids</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-2 col-6">
                  <input
                    className="form-control"
                    type="date"
                    value="02.05.2022"
                  />
                </div>
                <div className="col-md-2 col-6">
                  <div className="custom_select">
                    <select className="form-select select-nice">
                      <option selected="">Status</option>
                      <option>All</option>
                      <option>Paid</option>
                      <option>Chargeback</option>
                      <option>Refund</option>
                    </select>
                  </div>
                </div>
              </div>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th className="text-center" scope="col">
                          <div className="form-check align-middle">
                            <input
                              className="form-check-input"
                              id="transactionCheck01"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck01"
                            ></label>
                          </div>
                        </th>
                        <th className="align-middle" scope="col">
                          Order ID
                        </th>
                        <th className="align-middle" scope="col">
                          Billing Name
                        </th>
                        <th className="align-middle" scope="col">
                          Date
                        </th>
                        <th className="align-middle" scope="col">
                          Total
                        </th>
                        <th className="align-middle" scope="col">
                          View Details
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck02"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck02"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2540
                          </a>
                        </td>
                        <td>Neal Matthews</td>
                        <td>07 Oct, 2022</td>
                        <td>₹400</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck03"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck03"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2541
                          </a>
                        </td>
                        <td>Jamal Burnett</td>
                        <td>07 Oct, 2022</td>
                        <td>₹380</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck04"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck04"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2542
                          </a>
                        </td>
                        <td>Juan Mitchell</td>
                        <td>06 Oct, 2022</td>
                        <td>₹384</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck05"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck05"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2543
                          </a>
                        </td>
                        <td>Barry Dick</td>
                        <td>05 Oct, 2022</td>
                        <td>₹412</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck06"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck06"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2544
                          </a>
                        </td>
                        <td>Ronald Taylor</td>
                        <td>04 Oct, 2022</td>
                        <td>₹404</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              id="transactionCheck07"
                              type="checkbox"
                            />
                            <label
                              className="form-check-label"
                              for="transactionCheck07"
                            ></label>
                          </div>
                        </td>
                        <td>
                          <a className="fw-bold" href="#">
                            #SK2545
                          </a>
                        </td>
                        <td>Jacob Hunter</td>
                        <td>04 Oct, 2022</td>
                        <td>₹392</td>
                        <td>
                          <a className="btn btn-xs" href="#">
                            {" "}
                            View details
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                <li className="page-item active">
                  <a className="page-link" href="#">
                    01
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    02
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    03
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link dot" href="#">
                    ...
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    16
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Home;
