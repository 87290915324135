import "../../src/assets/css/style.css";
import Pageorderstracking from "../components/layouts/Pageorderstracking";
import Pageproductslist from "../components/layouts/Pageproductslist";
import Pagereviews from "../components/layouts/Pagereviews";
import Pagesellerdetail from "../components/layouts/Pagesellerdetail";
import Pagesellerslist from "../components/layouts/Pagesellerslist";
import Pagesettings from "../components/layouts/Pagesettings";
import Pagetransactions from "../components/layouts/Pagetransactions";
import Pagetransactiondetails from "../components/layouts/Pagetransactiondetails";
import Pageaccountlogin from "../components/layouts/Pageaccountlogin";
import Pageaccountregister from "../components/layouts/Pageaccountregister";
import Pageblank from "../components/layouts/Pageblank";
import Pagebrands from "../components/layouts/Pagebrands";
import Pagecategories from "../components/layouts/Pagecategories";
import Pageerror from "../components/layouts/Pageerror";
import Pageformproduct from "../components/layouts/Pageformproduct";
import Pageformprofit from "../components/layouts/Pageformprofit";
import Pageinvoice from "../components/layouts/Pageinvoice";
import Pageorders from "../components/layouts/Pageorders";
import Home from "../components/layouts/Home";
import Pageordersdetail from "../components/layouts/Pageordersdetail";
import Login from "../components/Login";
import InventoryPage from "../components/layouts/InventoryPage";
import VendorsPage from "../components/layouts/VendorsPage";
const authProtectedRoutes = [
  { path: "/home", component: Home },
  { path: "/pageordersdetail", component: Pageordersdetail },
  { path: "/pageordersdetail/:id", component: Pageordersdetail },
  { path: "/pageorderstracking", component: Pageorderstracking },
  { path: "/pageproductslist", component: Pageproductslist },
  { path: "/pagereviews", component: Pagereviews },
  { path: "/pagesellerdetail", component: Pagesellerdetail },
  { path: "/pagesellerslist", component: Pagesellerslist },
  { path: "/pagesettings", component: Pagesettings },
  { path: "/pagetransactions", component: Pagetransactions },
  { path: "/pagetransactiondetails", component: Pagetransactiondetails },
  { path: "/pageblank", component: Pageblank },
  { path: "/pagebrands", component: Pagebrands },
  { path: "/pagecategories", component: Pagecategories },
  { path: "/pageerror", component: Pageerror },
  { path: "/product", component: Pageformproduct },
  { path: "/product/add", component: Pageformproduct },
  { path: "/product/edit/:id", component: Pageformproduct },
  { path: "/pageformprofit", component: Pageformprofit },
  { path: "/pageinvoice", component: Pageinvoice },
  { path: "/pageorders", component: Pageorders },
  { path: "/inventory", component: InventoryPage },
  { path: "/vendor", component: VendorsPage },
  { path: "/vendor/add", component: VendorsPage },
  { path: "/vendor/edit/:id", component: VendorsPage },

];

const publicRoutes = [
  { path: "/login", component: Login },
  { path: "/pageaccountregister", component: Pageaccountregister },
];

export { authProtectedRoutes, publicRoutes };
