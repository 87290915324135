import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { productSelector } from "../../store/product/product.model";
import { useParams } from "react-router-dom";
import { NodeServer } from "../../services/config";
import { ProductAddListValiator } from "../../validators/Validators";
import {
  getProductRequest,
  postProductRequest,
  productStateResetRequest,
  UpdateProductRequest,
} from "../../store/product/product.action";
import { getCatRequest } from "../../store/category/category.action";
import { CatAddSelector } from "../../store/category/category.model";
import { getBrandRequest } from "../../store/brand/brand.action";
import { BrandSelector } from "../../store/brand/brand.model";
import { selectDefault, TaxPercentage } from "../../utils/Constants";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-dropdown-select";

function Pageformproduct() {
  const id = useParams();
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("id");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(ProductAddListValiator),
  });
  console.log(errors, "eireerei")
  /* Selectors */
  const { getCatData } = useSelector(CatAddSelector);
  const { getBrandData } = useSelector(BrandSelector);
  const { productSendSuccess, productUpdateSuccess, getProductData } =
    useSelector(productSelector);
  console.log(getProductData, "sldfsf8n");
  /* State */
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [ids, setId] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const onSubmitHandler = (formData) => {
    delete formData.category_id;
    if(!formData.tax){
      formData.tax=""
    }
    formData.image = image;
    formData.user_id = user_id;
    formData.unit_type = 0;
    formData.featured = "null";
    console.log(formData, "hjjak");
    if (ids === 0 || ids == undefined) {
      dispatch(postProductRequest(formData));
    } else {
      formData.id = ids;
      dispatch(UpdateProductRequest(formData));
    }
  };

  useEffect(() => {
    const payload = {
      query: "",
      page: "",
      limit: "",
    };
    dispatch(getCatRequest(payload));
    dispatch(getBrandRequest(payload));
    dispatch(productStateResetRequest());
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (id && id.id) {
      setId(id.id);
    }
  }, [id]);

  useEffect(() => {
    if (ids !== 0) {
      const payload = {
        query: "",
        prod_id: ids,
        page: 0,
        limit: 0,
      };
      dispatch(getProductRequest(payload));
    }
  }, [ids]);

  useEffect(() => {
    if (getProductData?.count > 0) {
      getProductData?.data?.map((ele) => {
        setImagePreview(
          ele?.image.includes("/products/")
            ? NodeServer.baseURL + ele?.image
            : NodeServer.baseURL + "/products" + ele?.image
        );

        reset({
          name: ele?.name,
          description: ele?.description,
          featured: ele?.featured,
          unit_type: ele?.unit_type,
          p_rate: ele?.p_rate,
          c_rate: ele?.c_rate,
          msrp: ele?.msrp,
          w_rate: ele?.w_rate,
        });
        let brandData=getBrandData?.data?.filter((ele1)=>
          ele1?.id==ele?.brand_id
         )
         setSelectedOption(brandData)
         setValue("brand_id", brandData[0].id)
         let catData=getCatData?.data?.filter((ele2)=>
          ele2?.id==ele?.catgory_id
         )
         setSelectedOption1(catData)
         setValue("catgory_id", catData[0].id)
         let taxData=TaxPercentage?.filter((tax)=>
        tax.value==ele?.tax
        )
        setSelectedOption2(taxData)
         console.log(brandData,taxData,catData, "isodfsfj")
      });
    }
  }, [getProductData]);

  useEffect(() => {
    if (productSendSuccess || productUpdateSuccess) {
      navigate("/pageproductslist");
      dispatch(productStateResetRequest());
      reset();
      setId(0);
      setImagePreview(null);
      setImage(null);
      if (imageRef.current) {
        imageRef.current.value = "";
      }
    }
  }, [productSendSuccess, productUpdateSuccess]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Row>
              <Col md={12}>
                <div className="content-header">
                  <h2 className="content-title">
                    {ids === 0 || ids == undefined
                      ? "Add New Product"
                      : "Edit Product"}
                  </h2>
                  <div>
                    {/* <button className="btn btn-light rounded font-sm mr-5 text-body hover-up">
                      Save to draft
                    </button> */}
                    <button
                      className="btn btn-md rounded font-sm hover-up"
                      type="submit"
                    >
                      Save Product
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={8}>
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Product Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label" htmlFor="product_name">
                            Product Title
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            {...register("name")}
                            placeholder="Type here"
                          />
                          {errors?.name && (
                            <p className="text-danger">
                              {errors?.name?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Description</label>
                          <textarea
                            className="form-control"
                            {...register("description")}
                            placeholder="Type here"
                            rows="4"
                          ></textarea>
                          {errors?.description && (
                            <p className="text-danger">
                              {errors?.description?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-4 overflow-visible">
                  <div className="card-header">
                    <h4>Price & Tax Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                    <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Tax %</label>
                          <Select
                        placeholder="Tax Percent"
                        className="form-control"
                        options={TaxPercentage||[]}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="name"
                        labelField={"name"}
                        valueField={"value"}
                        values={selectedOption2||[]}
                        name="name"
                        onChange={(selectedOption) =>{
                          setValue("tax", selectedOption[0].value)
                          setSelectedOption2(selectedOption)
                        }
                        }
                      />

                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">P. Price</label>
                          <input
                            className="form-control"
                            {...register("p_rate")}
                            placeholder="P. Price"
                            type="number"
                          />
                          {errors?.p_rate && (
                            <p className="text-danger">
                              {errors?.p_rate?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">C. Price</label>
                          <input
                            className="form-control"
                            {...register("c_rate")}
                            placeholder="C. Price"
                            type="number"
                          />
                          {errors?.c_rate && (
                            <p className="text-danger">
                              {errors?.c_rate?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      
                    </div>
                    <div className="row">
                    <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">M S R Price</label>
                          <input
                            className="form-control"
                            {...register("msrp")}
                            placeholder="MSR Price"
                            type="number"
                          />
                          {errors?.msrp && (
                            <p className="text-danger">
                              {errors?.msrp?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">W. Price</label>
                          <input
                            className="form-control"
                            {...register("w_rate")}
                            placeholder="W. Price"
                            type="number"
                          />
                          {errors?.w_rate && (
                            <p className="text-danger">
                              {errors?.w_rate?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    
                      
                    </div>
                  </div>
                </div>

                {/* <div className="card mb-4">
                  <div className="card-header">
                    <h4>Shipping</h4>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="width">
                              Width (inches)
                            </label>
                            <input
                              className="form-control"
                              id="width"
                              type="text"
                              // {...register("width")}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="height">
                              Height (inches)
                            </label>
                            <input
                              className="form-control"
                              id="height"
                              type="text"
                              // {...register("height")}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="weight">
                              Weight (grams)
                            </label>
                            <input
                              className="form-control"
                              id="weight"
                              type="text"
                              // {...register("weight")}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="mb-4">
                            <label
                              className="form-label"
                              htmlFor="shipping_fees"
                            >
                              Shipping Fees ($)
                            </label>
                            <input
                              className="form-control"
                              id="shipping_fees"
                              type="text"
                              // {...register("shipping_fees")}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> */}
              </Col>

              <Col lg={4}>
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Media</h4>
                  </div>
                  <div className="card-body">
                    <div className="input-upload">
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                      )}
                      <input
                        className="form-control"
                        type="file"
                        ref={imageRef}
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="card mb-4 overflow-visible" >
                  <div className="card-header">
                    <h4>Category & Brand</h4>
                  </div>
                  <div className="card-body">
                    <div className="row gx-2">
                      <div className="col-sm-6 mb-3">
                        <label className="form-label">Category</label>
                        {/* <select
                          className="form-select"
                          {...register("catgory_id")}
                        >
                          <option value={selectDefault} disabled>
                            SELECT
                          </option>
                          {getCatData?.data?.map((cat) => (
                            <option key={cat.id} value={cat.id}>
                              {cat.name}
                            </option>
                          ))}
                        </select>
                        {errors?.catgory_id && (
                          <p className="text-danger">
                            {errors?.catgory_id?.message}
                          </p>
                        )} */}
                             <Select
                        placeholder="Category"
                        className="form-control"
                        options={getCatData?.data||[]}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="name"
                        labelField={"name"}
                        valueField={"id"}
                        name="name"
                        values={selectedOption1||[]}
                        onChange={(selectedOption) =>{
                          setValue("catgory_id", selectedOption[0]?.id);
                          setSelectedOption1(selectedOption)
                        }
                        }
                      />
                      </div>

                      <div className="col-sm-6 mb-3">
                        <label className="form-label">Brand</label>
                        {/* <select
                          className="form-select"
                          {...register("brand_id")}
                        >
                          <option value={selectDefault} disabled>
                            SELECT
                          </option>
                          {getBrandData?.data?.map((brand) => (
                            <option key={brand.id} value={brand.id}>
                              {brand?.name}
                            </option>
                          ))}
                          {errors?.brand_id && (
                            <p className="text-danger">
                              {errors?.brand_id?.message}
                            </p>
                          )}
                        </select> */}
                            <Select
                        placeholder="Brand"
                        className="form-control"
                        options={getBrandData?.data||[]}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="name"
                        labelField={"name"}
                        valueField={"id"}
                        values={selectedOption||[]}
                        name="name"
                        onChange={(selectedOption) =>{
                          setValue("brand_id", selectedOption[0]?.id);
                          setSelectedOption(selectedOption)
                        }
                        }
                      />
                      </div>

                      {/* <div className="mb-4">
                        <label className="form-label">Tags</label>
                        <input
                          className="form-control"
                          // {...register("tags")}
                          placeholder="Type here"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </section>

        <Footer />
      </main>
    </>
  );
}

export default Pageformproduct;
