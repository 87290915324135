import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Footer from "../Footer";
import {
  ProductAddListValiator,
  VendorAddValidator,
} from "../../validators/Validators";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserDataRequest,
  getUserDataRequest,
  logout,
  updateUserRequest,
  userSelector,
} from "../../store/user/user.action";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
const VendorsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(VendorAddValidator),
  });
  /* Selectors */
  const { createSuccess, userData, updateSuccess } = useSelector(userSelector);
  console.log(userData, "userDAsa");

  /* State */
  const [ids, setId] = useState(0);
  console.log(ids, "sds");

  /* Fucntions */

  const onSubmitHandler = (formData) => {
    delete formData.role_id;

    console.log(formData, "sldjfsdsd");
    if (ids == 0) {
      dispatch(createUserDataRequest(formData));
    } else {
      const payload = {
        id: ids,
        formData,
      };
      dispatch(updateUserRequest(payload));
    }
  };

  /* useEffect */
  useEffect(() => {
    if (id && id.id) {
      setId(id.id);
    }
  }, [id]);

  useEffect(() => {
    if (ids > 0) {
      const payload = {
        query: "",
        page: 0,
        limit: 0,
        userId: ids,
      };
      dispatch(getUserDataRequest(payload));
    }
  }, [ids]);
  useEffect(() => {
    if (userData?.count > 0) {
      userData?.data?.map((ele) => {
        reset({
          email: ele?.email,
          mobile: ele?.mobile,
          name: ele?.name,
          password: ele?.password,
          role_id: ele?.role_id,
        });
      });
    }
  }, [userData]);
  useEffect(() => {
    if (createSuccess || updateSuccess) {
      navigate("/pagesellerslist");
      dispatch(logout());
      reset();
    }
  }, [createSuccess, updateSuccess]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Row>
              <Col md={12}>
                <div className="content-header">
                  <h2 className="content-title">
                    {ids === 0 || ids == undefined
                      ? "Add New Vendor"
                      : "Edit Vendor"}
                  </h2>
                  <div>
                    <button
                      className="btn btn-md rounded font-sm hover-up"
                      type="submit"
                    >
                      Save Vendor
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Basic Information</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="name">
                          Name
                        </label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          {...register("name")}
                          placeholder="Enter your name"
                        />
                        {errors?.name && (
                          <p className="text-danger">{errors?.name?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="email"
                          type="email"
                          {...register("email")}
                          placeholder="Enter your email"
                        />
                        {errors?.email && (
                          <p className="text-danger">
                            {errors?.email?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="mobile">
                          Mobile
                        </label>
                        <input
                          className="form-control"
                          id="mobile"
                          type="tel"
                          {...register("mobile")}
                          placeholder="Enter your mobile number"
                        />
                        {errors?.mobile && (
                          <p className="text-danger">
                            {errors?.mobile?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                        <input
                          className="form-control"
                          id="password"
                          type="password"
                          {...register("password")}
                          placeholder="Enter your password"
                        />
                        {errors?.password && (
                          <p className="text-danger">
                            {errors?.password?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="role_id">
                          GST Number
                        </label>
                        <input
                          className="form-control"
                          id="role_id"
                          type="text"
                          {...register("gst_no")}
                          placeholder="Enter GST Number"
                        />
                        {errors?.role_id && (
                          <p className="text-danger">
                            {errors?.role_id?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="city">
                          City
                        </label>
                        <input
                          className="form-control"
                          id="city"
                          type="text"
                          {...register("city")}
                          placeholder="Enter your city"
                        />
                        {errors?.city && (
                          <p className="text-danger">{errors?.city?.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="zipcode">
                          Zipcode
                        </label>
                        <input
                          className="form-control"
                          id="zipcode"
                          type="text"
                          {...register("zipcode")}
                          placeholder="Enter your zipcode"
                        />
                        {errors?.zipcode && (
                          <p className="text-danger">
                            {errors?.zipcode?.message}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="address">
                          Address
                        </label>
                        <textarea
                          className="form-control"
                          id="address"
                          {...register("address")}
                          placeholder="Enter your address"
                          rows="3"
                        ></textarea>
                        {errors?.address && (
                          <p className="text-danger">
                            {errors?.address?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default VendorsPage;
