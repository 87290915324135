import { decryptdata } from "../../utils/encrypt&decrypt";
import { productActionTypes, productInitialState } from "./product.model";

const reducer = (state = productInitialState, action) => {
  switch (action.type) {
    case productActionTypes.POSTPRODUCT_REQUEST:
      return {
        ...state,
        postProLoading: true,
        productSendSuccess: false,
      };
    case productActionTypes.POSTPRODUCT_SUCCESS:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: true,
      };
    case productActionTypes.POSTPRODUCT_FAILURE:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: false,
      };
    case productActionTypes.GETPRODUCT_REQUEST:
      return {
        ...state,
        getProLoading: true,
      };
    case productActionTypes.GETPRODUCT_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas1 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas1.data, "yhoio");
      return {
        ...state,
        getProLoading: false,
        getProductData: payloaddatas1,
      };
    case productActionTypes.GETPRODUCT_FAILURE:
      return {
        ...state,
        getProLoading: false,
      };
    case productActionTypes.UPDATEPRODUCT_REQUEST:
      return {
        ...state,
        updateProLoading: true,
        productUpdateSuccess: false,
      };
    case productActionTypes.UPDATEPRODUCT_SUCCESS:
      return {
        ...state,
        updateProLoading: false,
        productUpdateSuccess: true,
      };
    case productActionTypes.UPDATEPRODUCT_FAILURE:
      return {
        ...state,
        updateProLoading: false,
        productUpdateSuccess: false,
      };

    case productActionTypes.DELETEPRODUCT_REQUEST:
      return {
        ...state,
        deleteproductLoading: true,
        productDeleteSucces: false,
      };
    case productActionTypes.DELETEPRODUCT_SUCCESS:
      return {
        ...state,
        deleteproductLoading: false,
        productDeleteSucces: true,
      };
    case productActionTypes.DELETEPRODUCT_FAILURE:
      return {
        ...state,
        deleteproductLoading: false,
        productDeleteSucces: false,
      };

    case productActionTypes.STATE_RESET_REQUEST:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: false,
        getProLoading: false,
        updateProLoading: false,
        productUpdateSuccess: false,
        getProductData: [],
        deleteproductLoading: false,
        productDeleteSucces: false,
      };

    default:
      return state;
  }
};
export { reducer as productReducer };
