export const dashboardActionTypes = {
  GETCARDDATA_REQUEST: "@@dashboard/GETCARDDATA_REQUEST",
  GETCARDDATA_SUCCESS: "@@dashboard/GETCARDDATA_SUCCESS",
  GETCARDDATA_FAILURE: "@@dashboard/GETCARDDATA_FAILURE",

  GETDASHBOARDDATA_REQUEST: "@@dashboard/GETDASHBOARDDATA_REQUEST",
  GETDASHBOARDDATA_SUCCESS: "@@dashboard/GETDASHBOARDDATA_SUCCESS",
  GETDASHBOARDDATA_FAILURE: "@@dashboard/GETDASHBOARDDATA_FAILURE",
};

export const dashboardInitialState = {
  cardLoad: false,
  cardData: [],
  dashLoad: false,
  dashData: [],
};

export const dashboardSelector = (state) => state.dashboardReducer;
