import { Link, useNavigate } from "react-router-dom";
import logo from "../../src/assets/imgs/sikan-logo.png";
import { clearUserLogout } from "../store/auth/auth.action";
import { useDispatch } from "react-redux";

function Sidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <>
      <div className="screen-overlay"></div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">
          <Link className="brand-wrap" to="/home">
            <img className="logo" src={logo} alt="Evara Dashboard" />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted material-icons md-menu_open"></i>
            </button>
          </div>
        </div>
        <nav>
          <ul className="menu-aside">
            <li className="menu-item ">
              <Link className="menu-link" to="/home">
                <i className="icon material-icons md-home"></i>
                <span className="text">Dashboard</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pagecategories" >
                <i className="icon material-icons md-category"></i>
                <span className="text">Categories</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pagebrands" >
                <i className="icon material-icons md-loyalty"></i>
                <span className="text">Brands</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pageproductslist">
                <i className="icon material-icons md-shopping_bag"></i>
                <span className="text">Products</span>
              </Link>
            </li>
            <li className="menu-item">
              {/* <Link className="menu-link" to="/pageformprofit">
                <i className="icon material-icons md-monetization_on"></i>
                <span className="text">Set Profit</span>
              </Link> */}
              {/* <div className="submenu">
                <a href="page-transactions-1.html">Transaction 1</a>
                <a href="page-transactions-2.html">Transaction 2</a>
                <a href="page-transactions-details.html">Transaction Details</a>
              </div> */}
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pageorders">
                <i className="icon material-icons md-shopping_cart"></i>
                <span className="text">Orders</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/pagesellerslist">
                <i className="icon material-icons md-store"></i>
                <span className="text">Vendors</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link className="menu-link" to="/inventory">
                <i className="icon material-icons md-archive"></i>
                <span className="text">Inventory</span>
              </Link>
            </li>
            {/* <li className="menu-item has-submenu">
              <a className="menu-link" href="page-form-product-1.html">
                <i className="icon material-icons md-add_box"></i>
                <span className="text">Add product</span>
              </a>
              <div className="submenu">
                <a href="page-form-product-1.html">Add product 1</a>
                <a href="page-form-product-2.html">Add product 2</a>
                <a href="page-form-product-3.html">Add product 3</a>
                <a href="page-form-product-4.html">Add product 4</a>
              </div>
            </li> */}
            {/* <li className="menu-item has-submenu">
              <a className="menu-link" href="#">
                <i className="icon material-icons md-person"></i>
                <span className="text">Account</span>
              </a>
              <div className="submenu">
                <a href="page-account-login.html">User login</a>
                <a href="page-account-register.html">User registration</a>
                <a href="page-error-404.html">Error 404</a>
              </div>
            </li> */}
            {/* <li className="menu-item">
              <a className="menu-link" href="page-reviews.html">
                <i className="icon material-icons md-comment"></i>
                <span className="text">Reviews</span>
              </a>
            </li>
            <li className="menu-item">
              <a className="menu-link" href="page-brands.html">
                <i className="icon material-icons md-stars"></i>
                <span className="text">Brands</span>
              </a>
            </li>
            <li className="menu-item">
              <a className="menu-link" disabled="" href="#">
                <i className="icon material-icons md-pie_chart"></i>
                <span className="text">Statistics</span>
              </a>
            </li> */}
          </ul>
          <hr />
          <ul className="menu-aside">
            <li className="menu-item">
              <div className="menu-link" 
              onClick={() => {
                localStorage.clear();
                dispatch(clearUserLogout());
                navigate("/login");
              }}
            >
                <i className="material-icons md-exit_to_app" ></i>
                <span className="text" >Logout</span>
              </div>
              {/* <div className="submenu">
                <a href="page-settings-1.html">Setting sample 1</a>
                <a href="page-settings-2.html">Setting sample 2</a>
              </div> */}
            </li>
            {/* <li className="menu-item">
              <a className="menu-link" href="page-blank.html">
                <i className="icon material-icons md-local_offer"></i>
                <span className="text"> Starter page</span>
              </a>
            </li> */}
          </ul>
        </nav>
      </aside>
    </>
  );
}
export default Sidebar;
