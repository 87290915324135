import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import item5 from "../../assets/imgs/items/5.jpg";
import item6 from "../../assets/imgs/items/6.jpg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteProductRequest,
  getProductRequest,
  productStateResetRequest,
} from "../../store/product/product.action";
import { useEffect } from "react";
import { productSelector } from "../../store/product/product.model";
import { API_BASE } from "../../services/config";
import { useState } from "react";
import noImg from "../../assets/imgs/no-image.jpg";
function Pageproductslist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Selectors */
  const { getProductData, productDeleteSucces } = useSelector(productSelector);
  console.log(getProductData?.data, "34567");
  /* State */
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages1 = Math.ceil(getProductData?.count / rowsPerPage) || 1;

  const handleDelete = (id) => {
    dispatch(deleteProductRequest(id));
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const currentPage = page; // Replace with your actual current page state
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  /* useEffect */
  useEffect(() => {
    const payload = {
      query: searchQuery,
      prod_id: 0,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getProductRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage]);
  useEffect(() => {
    if (productDeleteSucces) {
      const payload = {
        query: "",
        prod_id: 0,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getProductRequest(payload));
      dispatch(productStateResetRequest());
    }
  }, [productDeleteSucces]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Products List</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              {/* <a className="btn btn-light rounded font-md" href="#">
                Export
              </a>
              <a className="btn btn-light rounded font-md" href="#">
                Import
              </a> */}
              <button
                className="btn btn-primary btn-sm rounded"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(productStateResetRequest());
                  navigate("/product/add");
                }}
              >
                {" "}
                Create new
              </button>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  {/* <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div> */}
                </div>
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  {/* <select className="form-select">
                    <option selected="">All category</option>
                    <option>Switches</option>
                    <option>Adapter</option>
                    <option>Panels</option>
                    <option>Wires</option>
                  </select> */}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-md-2 col-6">
                  <select className="form-select">
                    <option selected="">Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Show all</option>
                  </select>
                </div>
                {/* <div className="col-md-2 col-6">
                  <input
                    className="form-control"
                    type="date"
                    value="02.05.2022"
                  />
                </div> */}
                <div className="col-md-2 col-6">
                  <select
                    className="form-select"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value={10}>Show 10</option>
                    <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                  </select>
                </div>
              </div>
            </header>

            <div className="card-body">
              <article className="itemlist">
                <div className="row align-items-center">
                  {/* <div className="col col-check flex-grow-0">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-2 col-sm-2 col-4 col-price   ">
                    <h6 className="mb-0">Image</h6>
                  </div>

                  <div className="col-lg-2 col-sm-4 col-4 col-name flex-grow-1 px-4 ">
                    <h6 className="mb-0 ">Product Name</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Regular Price</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Sale Price</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-status">
                    <h6 className="mb-0">Inventory  <br />Status</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-date">
                    <h6 className="mb-0">Created Date</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                    <h6 className="mb-0">Action</h6>
                  </div>
                </div>
              </article>

              {getProductData?.data && getProductData?.data.length > 0 ? (
                getProductData?.data.map((product, index) => (
                  <article className="itemlist" key={product.id}>
                    <div className="row align-items-center">
                      {/* <div className="col col-check flex-grow-0">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" />
                        </div>
       
       </div> */}
                      <div className="col-lg-1 col-sm-4 col-4 flex-grow-1 col-name">
                        <div className="left">
                          <img
                            className="img-sm img-thumbnail"
                            src={
                              product?.image
                                ? product?.image?.startsWith("uploads/products")
                                  ? API_BASE + product.image
                                  : API_BASE + "/products" + product.image
                                : noImg
                            }
                            alt="Item"
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-4 col-8 flex-grow-1 col-name">
                        <a className="itemside" href="#">
                          <div className="info">
                            <h6 className="mb-0">{product.name}</h6>
                            <p className="text-muted">
                              {product.category.name}
                            </p>
                          </div>
                        </a>
                      </div>

                      <div className="col-lg-1 col-sm-2 col-4 col-price">
                        <span>₹{product.reg_price}</span>
                      </div>

                      <div className="col-lg-1 col-sm-2 col-4 col-price">
                        <span>₹{product.sale_price}</span>
                      </div>

                      <div className="col-lg-2 col-sm-2 col-4 col-status">
                        <span
                        className={`badge rounded-pill alert-${
                          product.product_inventory[0]?.total_qty > 0 ? "success" : "danger"
                        }`}
                        >
                          {product.product_inventory[0]?.total_qty > 0
                            ? "In Stock"
                            : "Out Of Stock"}
                        </span>
                      </div>

                      <div className="col-lg-1 col-sm-2 col-4 col-date">
                        <span>
                          {new Date(product.created_at).toLocaleDateString()}
                        </span>
                      </div>

                      <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                        <button
                          className="btn btn-sm font-sm rounded btn-brand mr-5"
                          onClick={() =>
                            navigate(`/product/edit/${product?.id}`)
                          }
                        >
                          <i className="material-icons md-edit"></i> Edit
                        </button>
                        <button
                          className="btn btn-sm font-sm btn-light rounded"
                          onClick={() => handleDelete(product.id)}
                        >
                          <i className="material-icons md-delete_forever"></i>{" "}
                          Delete
                        </button>
                      </div>
                    </div>
                  </article>
                ))
              ) : (
                <p>No products found</p>
              )}
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {pagesToDisplay.map((page) => {
                  return page != "..." ? (
                    <li
                      key={page}
                      className={`page-item b ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ) : page == "..." ? (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ) : (
                    ""
                  );
                })}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageproductslist;
