import { decryptdata } from "../../utils/encrypt&decrypt";
import { orderInitialState, orderActionTypes } from "./order.model";

const reducer = (state = orderInitialState, action) => {
  switch (action.type) {
    case orderActionTypes.GETUSERORDER_REQUEST:
      return {
        ...state,
        userOrderLoading: true,
      };
    case orderActionTypes.GETUSERORDER_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas3 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas3, "yhoioasd");
      return {
        ...state,
        userOrderLoading: false,
        userOrderData: payloaddatas3?.data,
      };
    case orderActionTypes.GETUSERORDER_FAILURE:
      return {
        ...state,
        userOrderLoading: false,
      };

    case orderActionTypes.ORDERDISPATCH_REQUEST:
      return {
        ...state,
        postProLoading: true,
        productSendSuccess: false,
      };
    case orderActionTypes.ORDERDISPATCH_SUCCESS:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: true,
      };
    case orderActionTypes.ORDERDISPATCH_FAILURE:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: false,
      };

    case orderActionTypes.ORDERDETAILBYID_REQUEST:
      return {
        ...state,
        orderDetailLoad: true,
      };
    case orderActionTypes.ORDERDETAILBYID_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmlss");
      let payloaddatas = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      return {
        ...state,
        orderDetailLoad: false,
        orderDetailData: payloaddatas?.response,
      };
    case orderActionTypes.ORDERDETAILBYID_FAILURE:
      return {
        ...state,
        orderDetailLoad: false,
      };

    case orderActionTypes.ORDERDSTATUSUPDADE_REQUEST:
      return {
        ...state,
        updateStatusLoad: true,
        updateStatusSuccess: false,
      };
    case orderActionTypes.ORDERDSTATUSUPDADE_SUCCESS:
      return {
        ...state,
        updateStatusLoad: false,
        updateStatusSuccess: true,
      };
    case orderActionTypes.ORDERDSTATUSUPDADE_FAILURE:
      return {
        ...state,
        updateStatusLoad: false,
        updateStatusSuccess: false,
      };
    case orderActionTypes.ORDERSTATE_RESET_REQUEST:
      return {
        ...state,
        updateStatusLoad: false,
        updateStatusSuccess: false,
      };
    default:
      return state;
  }
};
export { reducer as orderReducer };
