import { call, put, takeEvery } from "redux-saga/effects";
import * as orderAction from "./order.action";
import { orderActionTypes } from "./order.model";
import {
  getUserOrderDataApi,
  orderDetailDataApi,
  orderStatusUpdateApi,
  postOrderDispatchApi,
} from "./order.api";

function* handlegetUserOrderData(action) {
  try {
    const response = yield call(getUserOrderDataApi, action.payload);
    yield put(orderAction.getUserOrderDataSuccess(response));
  } catch (e) {
    yield put(orderAction.getUserOrderDataFailure(e));
  }
}
function* handleOrderDispatch(action) {
  try {
    const response = yield call(postOrderDispatchApi, action.payload);
    yield put(orderAction.orderDispatchSuccess(response));
  } catch (e) {
    yield put(orderAction.orderDispatchFailure(e));
  }
}

function* handleGetOrderDetailByID(action) {
  try {
    const response = yield call(orderDetailDataApi, action.payload);
    yield put(orderAction.orderDetailGetSuccess(response));
  } catch (e) {
    yield put(orderAction.orderDetailGetFailure(e));
  }
}
function* handleOrderStatusChange(action) {
  try {
    const response = yield call(orderStatusUpdateApi, action.payload);
    yield put(orderAction.UpdateOrderStatusSuccess(response));
  } catch (e) {
    yield put(orderAction.UpdateOrderStatusFailure(e));
  }
}
export function* orderSaga() {
  yield takeEvery(
    orderActionTypes.GETUSERORDER_REQUEST,
    handlegetUserOrderData
  );
  yield takeEvery(orderActionTypes.ORDERDISPATCH_REQUEST, handleOrderDispatch);
  yield takeEvery(
    orderActionTypes.ORDERDETAILBYID_REQUEST,
    handleGetOrderDetailByID
  );
  yield takeEvery(
    orderActionTypes.ORDERDSTATUSUPDADE_REQUEST,
    handleOrderStatusChange
  );
}
